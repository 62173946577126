import React, { Component } from 'react';
import SideMenuDesktop from 'src/components/parts/SideMenuDesktop';
import UserContainer from 'src/components/parts/UserContainer';
import {Helmet} from 'react-helmet';
import { connect } from "react-redux";
import { setText } from "src/cms.js";
import TextContent from 'src/components/parts/TextContent';
import Header from 'src/components/parts/Header';
import Footer from 'src/components/parts/Footer';


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  meta_title: state.meta_title,
	  meta_description: state.meta_description
	};
};

//	Main Home Class
class Home extends Component {

	constructor(props) {

		super(props);
		
		//	Set the page text ( meta_title / description )
		setText( props );
		
	}

	render() {
	  return (
		<div>
			<Header />
			<div className="container">
				<div className="wrap_sidemenu_also">
					<SideMenuDesktop />
					<UserContainer showOrderBox={true} />
				</div>
				<Helmet>
					<title>{this.props.meta_title}</title>
					<meta name="description" content={this.props.meta_description} />
				</Helmet>
				<TextContent />
				{ window.innerWidth > 768 ? <Footer /> : null }
			</div>
		</div>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Home);