//
//	Handle rendering of header, basicly for switches between mobile and desktop
//
import React from 'react';
import logo from 'public/static/img/logo.png';
import MobileMenu from 'src/components/parts/MobileMenu';
import UserSort from 'src/components/parts/UserSort';
import Search from 'src/components/parts/SearchBox';
import Register from 'src/components/parts/Register';
import Img from 'react-image';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
		top_text: state.top_text,
		usersTotal: state.usersTotal,
	};
};

class Header extends React.Component {

	constructor(props) {

		super(props);

	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	renderMobileHeader = () => {
		return(
			<div className="header_devices">
				<div className="logo"><a href="/"><Img src={logo} className="logo" alt="MySecretCams" /></a></div>
				<div className="toolbar">
					<UserSort />
					<MobileMenu />
				</div>
				<Register />
			</div>
		)
	}

	renderDesktopHeader = () => {
		return(
			<div className="header_container">
				<header>
					<div className="header_centered">
						<div className="header__pc_top">
							<div className="header__pc_top_left">
								<div className="header__pc_content_left">
									<div className="header__pc_logo"><a href="/"><Img src={logo} className="logo_pc" alt="MySecretCams Logo" title="Home Page" /></a></div>
								</div>
							</div>
							<div className="header__pc_top_right">
								<div className="header__pc_buttons">
									<a className="header__pc_button_login" title="Log In" onClick={this.loadRegister}>LOG IN</a>
									<a className="header__pc_button_account" title="Create Free Account" onClick={this.loadRegister}>CREATE FREE ACCOUNT</a>
								</div>
							</div>
						</div>
					</div>
				</header>
				<nav>
					<div className="nav_centered">
						<div className="navlinks">
							<NavLink to="/" className="navlink" activeClassName="navlink_active" exact>HOME</NavLink>
							<NavLink to="/page/asian/" className="navlink" activeClassName="navlink_active">ASIAN</NavLink>
							<NavLink to="/page/black/" className="navlink" activeClassName="navlink_active">BLACK</NavLink>
							<NavLink to="/page/latina/" className="navlink" activeClassName="navlink_active">LATINA</NavLink>
							<NavLink to="/page/white/" className="navlink" activeClassName="navlink_active">WHITE</NavLink>
						</div>
						<Search />
					</div>
				</nav>
				<Register />
			</div>
		)
	}

	
	render() {

		// At width 768 or greater we will display the desktop header ( default should always be for mobile )
		if(window.innerWidth > 768 ) {

			return(
				this.renderDesktopHeader()
			);

		} else {

			return (
				this.renderMobileHeader()
			);

		}
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Header);