import React from 'react';
import { NavLink } from 'react-router-dom';
import FiltersDesktop from 'src/components/parts/FiltersDesktop';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faThumbsUp, faEye, faStar, faFilter, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";


const mapStateToProps = state  => {
	return {

	};
};

class SideMenuDesktop extends React.Component {

	constructor(props) {

		super(props);

	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	render() {

		return (
			<div id="sidemenu__desktop">
				<div className="sidemenu">
					<div className="sidemenu_top">
						<div className="credits_box">
							<a className="promotions" title="Join and Earn Free Credits" onClick={this.loadRegister}><FontAwesomeIcon icon={faCoins} />PROMOTIONS</a>
							<h6>JOIN NOW to get FREE credits!</h6>	
						</div>
						<div className="sidemenu_top_options">
							<NavLink to="/page/top_girls/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faThumbsUp} /> TOP GIRLS</NavLink>
							<NavLink to="/page/hd/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faEye} /> HD WEBCAMS</NavLink>
							<NavLink to="/page/new/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faStar} /> NEW GIRLS</NavLink>
						</div>
					</div>
					<div className="sidemenu_filters">
						<h1>FILTER WEBCAMS<FontAwesomeIcon icon={faFilter} /></h1>
						<FiltersDesktop />
					</div>
					<div className="sidemenu_categories">
						<ul id="side_list">
							<li className="side_list_title">AGE<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/page/teens/" activeClassName="active_sidepage">Teens 18+</NavLink></li>
							<li><NavLink to="/page/twenties/" activeClassName="active_sidepage">Twenties (20 - 29)</NavLink></li>
							<li><NavLink to="/page/thirties/" activeClassName="active_sidepage">Thirties (30 - 39)</NavLink></li>
							<li><NavLink to="/page/forties/" activeClassName="active_sidepage">Forties (40 - 49)</NavLink></li>
							<li><NavLink to="/page/mature/" activeClassName="active_sidepage">Mature 50+</NavLink></li>
							<li className="side_list_title">ETHNICITY<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/page/asian/" activeClassName="active_sidepage">Asian Girls</NavLink></li>
							<li><NavLink to="/page/black/" activeClassName="active_sidepage">Black Girls</NavLink></li>
							<li><NavLink to="/page/latina/" activeClassName="active_sidepage">Latina Girls</NavLink></li>
							<li><NavLink to="/page/white/" activeClassName="active_sidepage">White Girls</NavLink></li>
							<li className="side_list_title">BODY TYPE<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/page/athletic/" activeClassName="active_sidepage">Athletic Models</NavLink></li>
							<li><NavLink to="/page/bbw/" activeClassName="active_sidepage">BBW Models</NavLink></li>
							<li><NavLink to="/page/muscular/" activeClassName="active_sidepage">Muscular Models</NavLink></li>
							<li><NavLink to="/page/petite/" activeClassName="active_sidepage">Petite Models</NavLink></li>
							<li className="side_list_title">FETISH<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/page/dominatrix/" activeClassName="active_sidepage">Dominatrix Shows</NavLink></li>
							<li><NavLink to="/page/slave/" activeClassName="active_sidepage">Slave Shows</NavLink></li>
							<li><NavLink to="/page/foot_fetish/" activeClassName="active_sidepage">Foot Fetish</NavLink></li>
							<li><NavLink to="/page/smoking/" activeClassName="active_sidepage">Smoking Shows</NavLink></li>
							<li className="side_list_title">TAGS<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/page/anal/" activeClassName="active_sidepage">Anal Sex</NavLink></li>
							<li><NavLink to="/page/busty/" activeClassName="active_sidepage">Big Tits</NavLink></li>
							<li><NavLink to="/page/blonde/" activeClassName="active_sidepage">Blonde</NavLink></li>
							<li><NavLink to="/page/brunette/" activeClassName="active_sidepage">Brunette</NavLink></li>
							<li><NavLink to="/page/redhead/" activeClassName="active_sidepage">Redhead</NavLink></li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps)(SideMenuDesktop);