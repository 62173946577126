import React from 'react';
import { connect } from "react-redux";
import { register } from "src/cms.js";

const mapStateToProps = state  => {
	return {
		registerOpen: state.registerOpen,
	};
};

class Register extends React.Component {

	constructor(props) {

		super(props);

	}

	closeRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	submitRegister(){

		//	Log the email address.
		register(document.getElementById("email").value);

	};

	renderRegister(){
		return (
			<div className="modal">
				<div className="model_register">
					<span className="close" onClick={this.closeRegister}>&times;</span>
					<h1>Creat Account</h1>
					<div className="registerForm">
						<input type="text" name="email" id="email" placeholder="Email address" />
						<button onClick={this.submitRegister}>Next</button>
					</div>
					<h2>Secret Sex Cams 100% Anonamous, Meet Like Minded Partners.</h2>
				</div>
			</div>
		);
	}
	

	render() {

		return (
			<div>
				{this.props.registerOpen ?  this.renderRegister() : null }
			</div>
		);
		
	}
}

export default connect(mapStateToProps)(Register);