import React, { Component } from 'react';
import { connect } from "react-redux";
import Img from 'react-image';
import logo from 'public/static/img/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faUserCircle, faDollarSign, faEnvelope } from "@fortawesome/free-solid-svg-icons";


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  footer_text: state.footer_text
	};
};

//	Main Home Class
class Footer extends Component {

	constructor(props) {

		super(props);
		
	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};


	render() {
	  return (
		<footer>
			<div className="footer_centered">
				<div className="footer_left">
					<div className="footer_left_top">
						<a href="/"><Img src={logo} className="footer_logo" alt="My Secret Cams" /></a>
					</div>
					<div className="footer_left_bottom">
						<h6>MySecretCams.com: Free anonymous chat with live cam girls! The best answer to safe online adult entertainment. Enjoy a real experience featuring fantastic and erotic sex on webcam!</h6>
					</div>
				</div>
				<div className="footer_right">
					<div>
						<h3>Work With Us <FontAwesomeIcon icon={faDollarSign} /></h3>
						<a href="/member/model-signup/">Be A Model</a>
						<a href="/member/affiliate-signup/">Webmasters</a>
					</div>
					<div>
						<h3>Discover More <FontAwesomeIcon icon={faUserCircle} /></h3>
						<a href="https://truehunks.com">Gay Cams</a>
						<a href="https://shemalewebcamshow.com/">Shemale Cams</a>
					</div>
					<div>
						<h3>Contact <FontAwesomeIcon icon={faEnvelope} /></h3>
						<a onClick={this.loadRegister}>Get In Touch</a>
					</div>
				</div>
			</div>
		</footer>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Footer);




