import React from 'react';
import { NavLink } from 'react-router-dom';
import Search from 'src/components/parts/SearchBox';
import Img from 'react-image';
import icon_menu from 'public/static/img/icon_menu.png';
import icon_search from 'public/static/img/icon_search.png';
import x_icon from 'public/static/img/x_icon.png';
import { connect } from "react-redux";


const mapStateToProps = state  => {
	return {
	};
};

class MobileMenu extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false }
		this.handleMenuButton = this.handleMenuButton.bind(this);

	}

	//	Update menu state
	handleMenuButton(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
		this.setState({ isOpen: false});
	};

	renderMobileMenuOpened = () => {

		return (
			<div className="menu_container">
				<div className="overlay" onClick={this.handleMenuButton}></div>
				<div id="menu">
					<div className="menu_top">
						<div className="free_account_btn"><a onClick={this.loadRegister}>FREE ACCOUNT</a></div>
						<div className="close_menu_btn" onClick={this.handleMenuButton}><Img src={x_icon} className="close_menu_icon" alt="Close Menu Icon" /></div>
					</div>
					<div className="menu_middle">
						<h1>Browse with ease and discover the hottest live chatrooms, secure, private, and 100% free only on mysecretcams.com</h1>
					</div>
					<Search />
					<ul className="menu_list">
						<li className="category_heading">AGE</li>
						<li><NavLink to="/page/teens/" activeClassName="active_menulink" onClick={this.handleMenuButton}>TEEN 18+</NavLink></li>
						<li><NavLink to="/page/twenties/" activeClassName="active_menulink" onClick={this.handleMenuButton}>TWENTIES (20 - 29)</NavLink></li>
						<li><NavLink to="/page/thirties/" activeClassName="active_menulink" onClick={this.handleMenuButton}>THIRTIES (30 - 39)</NavLink></li>
						<li><NavLink to="/page/forties/" activeClassName="active_menulink" onClick={this.handleMenuButton}>FORTIES (40 - 49)</NavLink></li>
						<li><NavLink to="/page/mature/" activeClassName="active_menulink" onClick={this.handleMenuButton}>MATURE 50+</NavLink></li>
						<li className="category_heading">ETHNICITY</li>
						<li><NavLink to="/page/asian/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ASIAN</NavLink></li>
						<li><NavLink to="/page/black/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BLACK</NavLink></li>
						<li><NavLink to="/page/latina/" activeClassName="active_menulink" onClick={this.handleMenuButton}>LATINA</NavLink></li>
						<li><NavLink to="/page/white/" activeClassName="active_menulink" onClick={this.handleMenuButton}>WHITE</NavLink></li>
						<li className="category_heading">BODY TYPE</li>
						<li><NavLink to="/page/athletic/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ATHLETIC</NavLink></li>
						<li><NavLink to="/page/bbw/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BBW</NavLink></li>
						<li><NavLink to="/page/muscular/" activeClassName="active_menulink" onClick={this.handleMenuButton}>MUSCULAR</NavLink></li>
						<li><NavLink to="/page/petite/" activeClassName="active_menulink" onClick={this.handleMenuButton}>PETITE</NavLink></li>
						<li className="category_heading">FETISH</li>
						<li><NavLink to="/page/dominatrix/" activeClassName="active_menulink" onClick={this.handleMenuButton}>DOMINATRIX</NavLink></li>
						<li><NavLink to="/page/slave/" activeClassName="active_menulink" onClick={this.handleMenuButton}>SLAVE</NavLink></li>
						<li><NavLink to="/page/foot_fetish/" activeClassName="active_menulink" onClick={this.handleMenuButton}>FOOT FETISH</NavLink></li>
						<li><NavLink to="/page/smoking/" activeClassName="active_menulink" onClick={this.handleMenuButton}>SMOKING</NavLink></li>
						<li className="category_heading">TAGS</li>
						<li><NavLink to="/page/anal/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ANAL SEX</NavLink></li>
						<li><NavLink to="/page/busty/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BIG TITS</NavLink></li>
						<li><NavLink to="/page/blonde/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BLONDE</NavLink></li>
						<li><NavLink to="/page/brunette/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BRUNETTE</NavLink></li>
						<li><NavLink to="/page/redhead/" activeClassName="active_menulink" onClick={this.handleMenuButton}>REDHEAD</NavLink></li>
					</ul>
				</div>
			</div>
		)
	}


	renderMobileMenuClosed = () => {

		return(
			<div className="menu_openers">
				<div className="open_search" onClick={this.handleMenuButton}>
					<Img src={icon_search} className="header_icon" alt="Search Icon" />
				</div>
				<div className="open_menu" onClick={this.handleMenuButton}>
					<Img src={icon_menu} className="header_icon" alt="Menu Icon" />
				</div>
			</div>
		)

	}

	render() {

		return (
			<div>
				{this.state.isOpen ?  this.renderMobileMenuOpened() : this.renderMobileMenuClosed()}
			</div>
		);
	}
}

export default connect(mapStateToProps)(MobileMenu);